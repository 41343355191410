import React, { useState, useEffect } from "react";
import axios from "axios";

export default function Delegates() {
  const [contractAddress, setContractAddress] = useState(
    "0x323A76393544d5ecca80cd6ef2A560C6a395b7E3"
  );
  const [delegates, setDelegates] = useState([]);
  const [mailchainUsers, setMailchainUsers] = useState([]);
  const [chainId, setChainId] = useState("");
  const [chains, setChains] = useState([]);
  const [chainsLoading, setChainsLoading] = useState(false);
  const [sortField, setSortField] = useState("ENS Name");
  const [sortDirection, setSortDirection] = useState("asc");

  useEffect(() => {
    // Fetch mailchain users from our backend
    axios
      .get("/api/mailchain-users")
      .then((response) => {
        setMailchainUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching mailchain users:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch chains from our backend
    setChainsLoading(true);
    axios
      .get("/api/chains")
      .then((response) => {
        setChains(response.data.chains);
      })
      .catch((error) => {
        console.error("Error fetching chains:", error);
      })
      .finally(() => {
        setChainsLoading(false);
      });
  }, []);

  const [loading, setLoading] = useState(false);
  const mailchainDelegateCount = delegates.filter((delegate) =>
    mailchainUsers.includes(delegate.address.toLowerCase())
  ).length;
  const sortedDelegates = [...delegates].sort((a, b) => {
    let comparison = 0;
    switch (sortField) {
      case "ENS Name":
        comparison = a.account.ens.localeCompare(b.account.ens);
        break;
      case "Address":
        comparison = a.address.localeCompare(b.address);
        break;
      case "Mailchain User":
        const aIsMailchainUser = mailchainUsers.includes(
          a.address.toLowerCase()
        )
          ? 1
          : 0;
        const bIsMailchainUser = mailchainUsers.includes(
          b.address.toLowerCase()
        )
          ? 1
          : 0;
        comparison = aIsMailchainUser - bIsMailchainUser;
        break;
    }
    return sortDirection === "desc" ? -comparison : comparison;
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // start loading
    try {
      const result = await axios.get("/api/delegates", {
        params: {
          governor: `${chainId}:${contractAddress}`,
        },
      });
      setDelegates(result.data.governance.delegates);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // end loading
    }
  };

  const handleSort = (field) => {
    if (field === sortField) {
      // If the same field is clicked, toggle the direction.
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Otherwise, update the sort field and reset the direction to ascending.
      setSortField(field);
      setSortDirection("asc");
    }
  };

  return (
    <div>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <label>
            Governor Contract Address:
            <input
              type="text"
              value={contractAddress}
              placeholder="0x323A76393544d5ecca80cd6ef2A560C6a395b7E3"
              onChange={(e) => setContractAddress(e.target.value)}
            />
          </label>
          <select
            value={chainId}
            onChange={(e) => setChainId(e.target.value)}
            disabled={chainsLoading}
          >
            <option value="" disabled>
              {chainsLoading ? "Loading chains..." : "Select a Chain"}
            </option>
            {[...chains]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((chain) => (
                <option key={chain.id} value={chain.id}>
                  {chain.name}
                </option>
              ))}
          </select>

          <button type="submit" className="btn-primary">
            Fetch Delegates
          </button>
        </form>
      </div>

      <div>
        <h2>Delegates:</h2>
        {loading ? (
          <p>Loading delegates...</p>
        ) : (
          <div>
            {delegates.length > 0 ? (
              <p>
                Summary:
                <ul>
                  <li>Numbers of delegates: {delegates.length}</li>
                  <li>
                    Numbers of ens users:{" "}
                    {
                      delegates.filter(
                        (delegate) => delegate.account.ens !== ""
                      ).length
                    }
                  </li>
                  <li>
                    Numbers of Mailchain users: {mailchainDelegateCount} (
                    {(
                      (mailchainDelegateCount / delegates.length) *
                      100
                    ).toFixed(3)}
                    %)
                  </li>
                </ul>
              </p>
            ) : (
              "Fetch some delegates!"
            )}
            <table className="table">
              <thead>
                <tr>
                  <th onClick={() => handleSort("ENS Name")}>ENS Name</th>
                  <th onClick={() => handleSort("Address")}>Address</th>
                  <th onClick={() => handleSort("Mailchain User")}>
                    Mailchain Users
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedDelegates.map((delegate) => (
                  <tr key={delegate.address}>
                    <td>
                      {delegate.account.ens !== ""
                        ? delegate.account.ens
                        : "N/A"}
                    </td>
                    <td>{delegate.address}</td>
                    <td>
                      {mailchainUsers.includes(delegate.address.toLowerCase())
                        ? "Yes"
                        : "No"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
