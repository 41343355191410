import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Dashboard from "./components/Dashboard";
import Delegates from "./components/Delegates";
import Holders from "./components/Holders";
import Insights from "./components/Insights";
import Login from "./components/Login";
import axios from "axios";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    axios
      .get("/api/auth/current_user")
      .then((response) => {
        if (response.data) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching current user:", error);
      });
  }, []);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          {isAuthenticated && (
            <>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/delegates" element={<Delegates />} />
              <Route path="/holders" element={<Holders />} />
              <Route path="/insights" element={<Insights />} />
              <Route path="/" element={<Delegates />} />
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
}
export default App;
