import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  LabelList,
} from "recharts";
import { useTable, useSortBy, useFilters } from "react-table";

const Insights = () => {
  const [data, setData] = useState([]);
  const [selectedInsight, setSelectedInsight] = useState("most-popular-tokens");
  const [networkData, setNetworkData] = useState({});
  const [selectedNetwork, setSelectedNetwork] = useState("eth-mainnet");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/insights/${selectedInsight}`);
        const result = await response.json();
        setNetworkData(result[0]);
        const tokens = result[0][selectedNetwork];
        if (selectedInsight === "most-popular-tokens") {
          setData(tokens.slice(0, 100));
        } else {
          setData(tokens);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedInsight, selectedNetwork]);

  const handleNetworkChange = (event) => {
    setSelectedNetwork(event.target.value);
    setData(networkData[event.target.value].slice(0, 100));
  };

  const handleInsightChange = (event) => {
    setSelectedInsight(event.target.value);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Contract Name",
        accessor: "contract_name",
      },
      {
        Header: "Count",
        accessor: "count",
      },
      {
        Header: "Contact Address",
        accessor: "_id",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useFilters, useSortBy);

  const tableStyles = {
    border: "1px solid #ddd",
    width: "100%",
    marginTop: "20px",
    borderCollapse: "collapse",
  };

  const thStyles = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f2f2f2",
  };

  const tdStyles = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };


  return (
    <div>
      <div>
        <h1>Insights</h1>
        <select
          value={selectedInsight}
          onChange={handleInsightChange}
        >
          <option value="most-popular-tokens">Most Popular Tokens</option>
        </select>
      </div>
      {selectedInsight === "most-popular-tokens" && (
        <div>
          <div>
            {/* Network Dropdown */}
            <select
              value={selectedNetwork}
              onChange={handleNetworkChange}
            >
              <option value="eth-mainnet">Ethereum Mainnet</option>
              <option value="matic-mainnet">Matic Mainnet</option>
            </select>
          </div>

          {/* Chart */}

          <BarChart width={800} height={1500} layout="vertical" data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="contract_name" type="category" width={300} />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#8884d8">
              <LabelList dataKey="count" position="right" />
            </Bar>
          </BarChart>

          {/* Table */}
          <table {...getTableProps()} style={tableStyles}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={thStyles}
                    >
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.slice(0, 1000).map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} style={tdStyles}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Insights;
