import React, { useState } from "react";
import axios from "axios";

export default function Login() {
  const [mailchain_address, setMailchainAddress] = useState("");
  const [message, setMessage] = useState("");
  const handleLogin = () => {
    axios
      .post("/api/auth/magiclink", { mailchain_address: mailchain_address })
      .then((response) => {
        if (response.data.success) {
          setMessage(response.data.message);
        } else {
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        setMessage("An error occurred. Please try again.");
      });
  };

  return (
    <div>
      <h2>Login with Magic Link</h2>
      <input
        type="email"
        value={mailchain_address}
        onChange={(e) => setMailchainAddress(e.target.value)}
        placeholder="Enter your Mailchain address"
      />
      <button onClick={handleLogin}>Send Magic Link</button>
      {message && <p>{message}</p>}
    </div>
  );
}
