import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import userDocumentation from "../docs/user.md";

const navStyle = {
  display: "flex",
  justifyContent: "space-between",
  padding: "10px",
  background: "#333",
};

const listStyle = {
  listStyleType: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  gap: "10px",
};

const linkStyle = {
  color: "#fff",
  textDecoration: "none",
  padding: "5px 10px",
  border: "1px solid #fff",
  borderRadius: "5px",
  backgroundColor: "transparent",
  fontSize: "16px"
};

const logout = () => {
  axios
    .post("/api/auth/logout")
    .then((response) => {
      window.location.href = "/login";
    })
    .catch((error) => {
      console.error("Logout error:", error);
    });
};

const Navbar = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [markdownData, setMarkdownData] = useState("");
  
  // Fetch markdown content when the component mounts
  useEffect(() => {
    fetch(userDocumentation)
      .then((response) => response.text())
      .then((text) => setMarkdownData(text));
  }, []);

  useEffect(() => {
    axios
      .get("/api/auth/current_user")
      .then((response) => {
        if (response.data) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching current user:", error);
      });
  }, []);

  return (
    <nav style={navStyle}>
      {isAuthenticated ? (
        <ul style={listStyle}>
          <li>
            <Link to="/dashboard" style={linkStyle}>
              Dashboard
            </Link>
          </li>
          <li>
            <Link to="/insights" style={linkStyle}>
              Insights
            </Link>
          </li>
          <li>
            <Link to="/delegates" style={linkStyle}>
              Delegates
            </Link>
          </li>
          <li>
            <Link to="/holders" style={linkStyle}>
              Holders
            </Link>
          </li>
        </ul>
      ) : (
        // This div will take up the space and push the Login/Logout button to the right
        <div style={{ flexGrow: 1 }}></div>
      )}
      <ul style={listStyle}>
        <li>
          {isAuthenticated ? (
            <button style={linkStyle} onClick={logout}>
              Logout
            </button>
          ) : (
            <Link to="/login" style={linkStyle}>
              Login
            </Link>
          )}
        </li>
        <li>
          <a href="#" style={linkStyle} onClick={() => setModalOpen(true)}>
            Docs
          </a>
          {/* Modal */}
          {isModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={() => setModalOpen(false)}>
                  &times;
                </span>
                <ReactMarkdown>{markdownData}</ReactMarkdown>
              </div>
            </div>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
