import React, { useState, useEffect } from "react";
import axios from "axios";

export default function Holders() {
  const [chainId, setChainId] = useState("1");
  const [contractType, setContractType] = useState("erc20");
  const [minBalance, setMinBalance] = useState(0);
  const [contractAddress, setContractAddress] = useState("");
  const [holders, setHolders] = useState([]);
  const [mailchainUsers, setMailchainUsers] = useState([]);
  const [chains, setChains] = useState([]);
  const [sortField, setSortField] = useState("address");
  const [sortDirection, setSortDirection] = useState("asc");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch mailchain users from our backend
    axios
      .get("/api/mailchain-users")
      .then((response) => {
        setMailchainUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching mailchain users:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch covalent chains
    axios
      .get("/api/covalentChains")
      .then((response) => {
        setChains(response.data);
      })
      .catch((error) => {
        console.error("Error fetching chains:", error);
      });
  }, []);

  const [pagination, setPagination] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when starting the fetch

    const queryParams = {
      chainId,
      contractType,
      minBalance,
      contractAddress
    };

    try {
      const result = await axios.get("/api/holders", { params: queryParams });
      setHolders(result.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false when fetching is done
    }
  };

  const handleSort = (field) => {
    if (field === sortField) {
      // Toggle sort direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const sortedHolders = [...holders].sort((a, b) => {
    let comparison = 0;
    switch (sortField) {
      case "address":
        comparison = a.address.localeCompare(b.address);
        break;
      case "balance":
        comparison = parseFloat(a.balance) - parseFloat(b.balance);
        break;
      case "registered":
        const aIsRegistered = mailchainUsers.includes(a.address.toLowerCase())
          ? 1
          : 0;
        const bIsRegistered = mailchainUsers.includes(b.address.toLowerCase())
          ? 1
          : 0;
        comparison = aIsRegistered - bIsRegistered;
        break;
      default:
        break;
    }
    return sortDirection === "asc" ? comparison : -comparison;
  });

  const mailchainUserCount = holders.filter(holder => 
    mailchainUsers.includes(holder.address.toLowerCase())
).length;

  return (
    <div>
      {/* Form */}
      <form onSubmit={handleSubmit}>
        <label>
          Chain:
          <select value={chainId} onChange={(e) => setChainId(e.target.value)}>
            {chains.map((chain) => (
              <option key={chain.id} value={chain.id}>
                {chain.name}
              </option>
            ))}
          </select>
        </label>

        <label>
          Contract Type:
          <select
            value={contractType}
            onChange={(e) => setContractType(e.target.value)}
          >
            <option value="erc-20">ERC-20</option>
            <option value="erc-721">ERC-721</option>
            <option value="erc-1155">ERC-1155</option>
          </select>
        </label>

        <label>
          Min Balance:
          <input
            type="number"
            value={minBalance}
            onChange={(e) => setMinBalance(parseFloat(e.target.value))}
          />
        </label>

        <label>
          Contract Address:
          <input
            type="text"
            value={contractAddress}
            onChange={(e) => setContractAddress(e.target.value)}
          />
        </label>

        <button type="submit">Fetch Holders</button>
      </form>

      {/* Holders Display */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {holders.length > 0 ? (
            <div>
            <h2>
              <img
                src={holders[0]["logo_url"]}
                alt="logo"
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
              {holders[0]["contract_name"]}
            </h2>
            <p>Summary:
                <ul>
                    <li>Numbers of addresses: {holders.length}</li>
                    <li>Numbers of Mailchain users: {mailchainUserCount} (
                    {(
                      (mailchainUserCount / holders.length) *
                      100
                    ).toFixed(3)}
                    %)
                    </li>
                </ul>
            </p>
            </div>
          ) : (
            "Fetch some holders!"
          )}

          <table>
            <thead>
              <tr>
                <th onClick={() => handleSort("address")}>Address</th>
                <th onClick={() => handleSort("balance")}>Balance</th>
                <th onClick={() => handleSort("registered")}>
                  Registered with Mailchain
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedHolders.map((holder) => (
                <tr key={holder.address}>
                  <td>{holder.address}</td>
                  <td>{holder.balance}</td>
                  <td>
                    {mailchainUsers.includes(holder.address.toLowerCase())
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
